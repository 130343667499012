"use client";

import React from "react";
import { htmlToReactParser } from "../../utils";
import Link from "next/link";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from '@fortawesome/free-regular-svg-icons'
import { faLinkedin, faInstagram } from '@fortawesome/free-brands-svg-icons'

import Image from 'next/image';

const RenderSocials = ({ data, mobileView }) => {
  const { heading, subheading, media, linkedin, email, instagram } = data;

  return (
    <>
    <div className="md:hidden">
      {htmlToReactParser(heading)}
    </div>
		<div className={`flex gap-8`}>
      <Image
        src={media.desktop?.src}
        width={183}
        height={183}
        alt="Picnic employee profile picture"
        className="bg-green5 rounded-full w-28 h-28 md:h-48 md:w-48 object-contain"
        />
      <div className="w-1/2">
        <div className="hidden md:block">
          {htmlToReactParser(heading)}
        </div>
        {htmlToReactParser(subheading)}
        <div className="flex gap-4 mt-3.5">
          <Link href={linkedin}><FontAwesomeIcon icon={faLinkedin} size="lg" className="text-grey3"/></Link>
          <Link href={`mailto:${email}`}><FontAwesomeIcon icon={faEnvelope} size="lg" className="text-grey3"/></Link>
          <Link href={instagram}><FontAwesomeIcon icon={faInstagram} size="lg" className="text-grey3"/></Link>
        </div>
      </div>
		</div>
    </>
	);
};

export default RenderSocials;