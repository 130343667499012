import React, { useEffect, useState } from 'react'
import "../confetti.css";

const ConfettiEffect: React.FC = () => {
  const [confetti, setConfetti] = useState<JSX.Element[]>([])

  useEffect(() => {
    const colors = ['#ff0000', '#00ff00', '#0000ff', '#ffff00', '#ff00ff', '#00ffff']
    const newConfetti = []

    for (let i = 0; i < 100; i++) {
      const left = Math.random() * 100
      const animationDuration = Math.random() * 3 + 2
      const color = colors[Math.floor(Math.random() * colors.length)]

      newConfetti.push(
        <div
          key={i}
          style={{
            position: 'fixed',
            left: `${left}%`,
            top: '-5%',
            width: '10px',
            height: '10px',
            backgroundColor: color,
            borderRadius: '50%',
            animation: `fall ${animationDuration}s linear`,
            zIndex: 99
          }}
        />
      )
    }

    setConfetti(newConfetti)

    return () => {
      setConfetti([])
    }
  }, [])

  return (
    <>
      <style jsx global>{`
        @keyframes fall {
          from {
            transform: translateY(-5vh) rotate(0deg);
          }
          to {
            transform: translateY(105vh) rotate(360deg);
          }
        }
      `}</style>
      {confetti}
    </>
  )
}

export default ConfettiEffect

