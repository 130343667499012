"use client";

import React, { forwardRef, useImperativeHandle } from "react";
import { useCallback, useEffect, useRef, useState } from "react";
import {
	PlayArrowRounded,
	PauseRounded,
	SkipNextRounded,
	SkipPreviousRounded,
} from "@mui/icons-material";

interface PodcastPlayerProps {
	src: string;
	onNext?: () => void;
	onPrevious?: () => void;
	audioRef: any;
}

export const PodcastPlayer = forwardRef((props: PodcastPlayerProps) => {
	const { src, onNext, onPrevious, audioRef } = props;
	const [isPlaying, setIsPlaying] = useState(false);
	const [currentTime, setCurrentTime] = useState(0);
	const [duration, setDuration] = useState(0);
	const [initialLoad, setInitialLoad] = useState(false);

	useEffect(() => {
		if (!audioRef.current) return;

		const onLoadedMetadata = () => {
			handleLoadedMetadata();
		};

		const audio = audioRef.current;

		audio.addEventListener("loadedmetadata", onLoadedMetadata);

		return () => {
			audio.removeEventListener("loadedmetadata", onLoadedMetadata);
		};
	}, [audioRef, src]);

	const togglePlay = useCallback(() => {
		if (audioRef.current) {
			if (isPlaying) {
				audioRef.current.pause();
			} else {
				audioRef.current.play();
			}
			setIsPlaying(!isPlaying);
		}
	}, [isPlaying]);

	const handleTimeUpdate = useCallback(() => {
		if (audioRef.current) {
			setCurrentTime(audioRef.current.currentTime);
		}
	}, []);

	const handleLoadedMetadata = useCallback(() => {
		if (audioRef.current && audioRef.current.duration) {
			setDuration(audioRef.current.duration);
		}
	}, [audioRef, src]);

	const handleSeek = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
		const time = Number(e.target.value);
		if (audioRef.current) {
			audioRef.current.currentTime = time;
			setCurrentTime(time);
		}
	}, []);

	const handleTimelineClick = useCallback(
		(e: React.MouseEvent<HTMLInputElement>) => {
			const element = e.currentTarget;
			const rect = element.getBoundingClientRect();
			const x = e.clientX - rect.left;
			const percentage = x / element.offsetWidth;
			const newTime = percentage * duration;

			if (audioRef.current) {
				audioRef.current.currentTime = newTime;
				setCurrentTime(newTime);
			}
		},
		[duration]
	);

	const formatTime = (time: number) => {
		const minutes = Math.floor(time / 60);
		const seconds = Math.floor(time % 60);
		return `${minutes}:${seconds.toString().padStart(2, "0")}`;
	};

	const handlePrevious = useCallback(() => {
		if (audioRef.current) {
			if (audioRef.current.currentTime > 4) {
				audioRef.current.currentTime = 0;
				audioRef.current.play();
				setIsPlaying(true);
			} else if (onPrevious) {
				onPrevious();
			}
		}
	}, [onPrevious]);

	useEffect(() => {
		const audio = audioRef.current;
		if (audio) {
			if (audio.duration) {
				handleLoadedMetadata();
			}
			audio.addEventListener("timeupdate", handleTimeUpdate);
			audio.addEventListener("ended", () => {
				if (onNext) {
					setTimeout(() => onNext(), 2000);
				}
			});

			return () => {
				audio.removeEventListener("timeupdate", handleTimeUpdate);
				audio.removeEventListener("ended", () => {
					if (onNext) {
						setTimeout(() => onNext(), 2000);
					}
				});
			};
		}
	}, [handleTimeUpdate, audioRef, src, onNext]);

	return (
		<div className="bg-transparent md:bg-grey0 rounded-xl shadow-lg px-6 pt-10 pb-2 !w-full mx-auto">
			<audio
				ref={audioRef}
				src={src}
				onPlaying={() => setIsPlaying(true)}
			/>
			{/* Timeline */}
			<div className="flex items-center gap-2 mb-1">
				<input
					type="range"
					min={0}
					max={duration}
					value={currentTime}
					onChange={handleSeek}
					onClick={handleTimelineClick}
					className="cursor-pointer flex-1 h-4 bg-transparent
						[&::-webkit-slider-runnable-track]:rounded-lg
						[&::-webkit-slider-runnable-track]:bg-grey2
						[&::-webkit-slider-runnable-track]:h-1
						[&::-moz-range-track]:rounded-lg
						[&::-moz-range-track]:bg-grey2
						[&::-moz-range-track]:h-1

            [&::-webkit-slider-thumb]:opacity-0
            [&::-moz-range-thumb]:opacity-0

            [&::-webkit-progress-value]:bg-green7
            [&::-webkit-progress-value]:rounded-lg

            [&::-webkit-progress-bar]:bg-green7
            [&::-moz-range-progress]:bg-green7
            [&::-moz-range-progress]:rounded-lg

            [&::-webkit-progress-bar]:bg-green7
            [&::-moz-range-progress]:bg-green7
            [&::-moz-range-progress]:rounded-lg


						[&::-webkit-slider-thumb]:appearance-none
						[&::-webkit-slider-thumb]:w-0
						[&::-webkit-slider-thumb]:h-0
						[&::-webkit-slider-thumb]:bg-transparent

						[&::-moz-range-thumb]:appearance-none
						[&::-moz-range-thumb]:w-0
						[&::-moz-range-thumb]:h-0
						[&::-moz-range-thumb]:bg-transparent
            
          

						relative
						before:absolute
						before:h-1
						before:bg-green7
						before:rounded-lg
						before:top-[calc(50%-2px)]
						before:left-0
						before:w-[calc((var(--value-percent,0)/100)*100%)]"
					style={
						{
							"--value-percent":
								(currentTime / duration) * 100 || 0,
						} as React.CSSProperties
					}
				/>
			</div>
			<div className="flex justify-between font-plus-jakarta-sans">
				<p className="text-sm text-grey5">{formatTime(currentTime)}</p>
				<p className="text-sm text-grey5">{formatTime(duration)}</p>
			</div>

			{/* Controls */}
			<div className="flex justify-center items-center gap-4">
				<button
					onClick={handlePrevious}
					className="p-2 hover:bg-grey1 rounded-full transition-colors"
					disabled={!onPrevious}
				>
					<SkipPreviousRounded className="!w-10 !h-10 text-grey5" />
				</button>

				<button onClick={togglePlay} className="w-20 h-20 text-grey5">
					{isPlaying ? (
						<PauseRounded className="!w-full !h-full" />
					) : (
						<PlayArrowRounded className="!w-full !h-full" />
					)}
				</button>

				<button
					onClick={onNext}
					className="p-2 hover:bg-grey1 rounded-full transition-colors"
					disabled={!onNext}
				>
					<SkipNextRounded className="!w-10 !h-10 text-grey5" />
				</button>
			</div>
		</div>
	);
});
