"use client";

import React, { useState, useEffect, useRef } from "react";
import { PodcastPlayer } from "../../PodcastPlayer";
import podcasts from "@/public/assets/tenyears/podcast/info.json";
import { PlayArrowRounded } from "@mui/icons-material";
import Image from "next/image";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useGSAP } from "@gsap/react";
import { caveat } from "@/sites/events/utils/fonts";

export const PodcastHero = ({ data }: { data: any }) => {
	const [currentEpisode, setCurrentEpisode] = useState(0);
	const [currentImage, setCurrentImage] = useState("");
	const [nextImage, setNextImage] = useState("");
	const [isTransitioning, setIsTransitioning] = useState(false);
  const playerRef = useRef(null);

  const { title, bubble1, bubble2, bubble3 } = data;

  useEffect(() => {
		setCurrentImage(podcasts[currentEpisode].image);
  }, [currentEpisode]);


  const handleCardPlay = (index: number) => {
		if (playerRef.current) {
			playerRef.current.play();
		}
  };

  const handleImageChange = (newIndex: number) => {
		if (isTransitioning) {
			return;
		}
		setNextImage(podcasts[newIndex].image);
		setIsTransitioning(true);
		setCurrentEpisode(newIndex);

		const timeout = setTimeout(() => {
			setIsTransitioning(false);
			setCurrentImage(podcasts[newIndex].image);
		}, 500);

		return () => clearTimeout(timeout);
  };

  const handleNext = () => {
		const nextIndex = (currentEpisode + 1) % podcasts.length;
		handleImageChange(nextIndex);
  };

  const handlePrevious = () => {
		const previousIndex =
			(currentEpisode - 1 + podcasts.length) % podcasts.length;
		handleImageChange(previousIndex);
  };

  const backgroundStyle = {
		maskImage: "url(/assets/tenyears/img/podcast-mask-shape.png)",
		maskSize: "98% 96%",
		maskPosition: "center",
		maskRepeat: "no-repeat",
		backgroundSize: "cover",
		backgroundPosition: "center",
		backgroundImage: "url('/assets/tenyears/img/squiggly_line5.png')",
		backgroundRepeat: "no-repeat",
  };

  const PodcastList = () => {
		// Define proper types for the participant
		interface Participant {
			name: string;
			img?: string;
		}

		interface PodcastData {
			title: string;
			image: string;
			src: string;
			participants: Participant[];
			year: string;
			description: string;
		}

		const Card = ({
			podcast,
			index,
		}: {
			podcast: PodcastData;
			index: number;
		}) => {
			const cardRef = useRef<HTMLDivElement>(null);
			return (
				<div
					className="h-[400px] w-[370px] text-grey4 rounded-lg shadow bg-white"
					ref={cardRef}
				>
					<div className="p-4 rounded-lg h-full">
						<div className="flex flex-col justify-between h-full gap-2">
							<div className="flex flex-col gap-3">
								<div className="flex items-center justify-between gap-2">
									<p className="text-sm">
										Episode{" "}
										{index + 1 < 10
											? `0${index + 1}`
											: index + 1}{" "}
										•{" "}
										{podcast.participants
											.map(
												(participant: any) =>
													participant.name
											)
											.join(" & ")}
									</p>
									<div className="bg-green3 rounded-full px-3 py-1 flex gap-2 items-center">
										<p className="text-sm font-bold text-white text-sm">
											{podcast.year}
										</p>
										{podcast.participants[0].img && (
											<div className="flex">
												{podcast.participants.map(
													(participant, _index) =>
														participant.img && (
															<div
																key={_index}
																className={`aspect-square w-6 h-6 -mr-2 ${index === 0 ? "z-20" : ""}`}
															>
																<Image
																	src={
																		participant.img
																	}
																	alt={`Participant ${_index + 1}`}
																	width={0}
																	height={0}
																	className="rounded-full w-full h-full object-cover"
																/>
															</div>
														)
												)}
											</div>
										)}
									</div>
								</div>
								<h3 className="text-4xl w-2/3 font-bold font-forma-djr-display m-0 leading-none">
									{podcast.title}
								</h3>
								<p className="text-sm font-plus-jakarta-sans mt-2">
									{podcast.description}
								</p>
							</div>
							<button
								onClick={() => handleCardPlay(index)}
								className="w-10 h-10 text-grey5 bg-grey1 rounded-full flex items-center justify-center p-1"
							>
								<PlayArrowRounded className="!w-full !h-full" />
							</button>
						</div>
					</div>
				</div>
			);
		};

		const cardsRef = useRef<HTMLDivElement>(null);
		const containerRef = useRef<HTMLDivElement>(null);

		return (
			<div
				className="md:px-12 md:py-20 py-8 overflow-hidden max-w-screen-2xl mx-auto"
				ref={containerRef}
			>
				<div className="relative w-full flex flex-col md:flex-row">
					<div className="w-full md:w-2/3">
						<Image
							src={podcasts[currentEpisode]?.image || ""}
							alt={podcasts[currentEpisode]?.title || ""}
							width={0}
							height={0}
							className="md:rounded-lg h-auto object-cover w-full md:aspect-[5/3] aspect-[3/2]"
						/>
					</div>
					<div
						className="w-full md:w-1/3 px-6 md:px-0"
						ref={cardsRef}
					>
						<div className="flex gap-4  h-full relative mx-auto justify-center md:justify-start items-center md:-ml-20 -mt-10 md:mt-0">
							{podcasts.map((podcast, index) => {
								if (!podcast.src || !podcast.participants)
									return null;
								return (
									<Card
										key={index}
										podcast={podcast}
										index={index}
									/>
								);
							})}
						</div>
					</div>
				</div>
			</div>
		);
  };

  const PodcastContent = () => {
		const PlayerInfo = () => {
			return (
				<div className="bg-white pt-6 md:pt-0 rounded-xl relative z-50 flex flex-col items-start justify-end h-full text-grey4 w-full float-right">
					<div className="w-full md:w-2/3 p-4 md:px-12 pb-0">
						<div className="flex flex-col md:pb-10 px-6 md:px-0">
							<p className="hidden md:block font-plus-jakarta-sans mb-4">
								Now Playing...
							</p>
							<h1 className="text-2xl md:text-5xl font-bold font-forma-djr-display m-0 leading-none">
								{podcasts[currentEpisode].title}
							</h1>
							<p className="md:text-xl font-plus-jakarta-sans">
								Episode{" "}
								{currentEpisode + 1 < 10
									? `0${currentEpisode + 1}`
									: currentEpisode + 1}{" "}
								•{" "}
								{podcasts[currentEpisode].participants
									.map((participant: any) => participant.name)
									.join(" & ")}
							</p>
						</div>
						<PodcastPlayer
							src={`/assets/tenyears/podcast/${podcasts[currentEpisode].src}`}
							onNext={handleNext}
							onPrevious={handlePrevious}
							audioRef={playerRef}
						/>
					</div>
				</div>
			);
		};

		const Logo = () => {
			return (
				<div className="flex justify-center items-center h-full">
					<div className="text-center text-grey5">
						<Image
							className="mx-auto h-32 w-auto mb-4"
							src="/assets/tenyears/img/pubcast-mic.png"
							alt="Picnic Pubcast"
							width={0}
							height={0}
						/>
						<p className="text-xl mb-2">Welcome to the</p>
						<h1 className="text-5xl md:text-6xl font-extrabold font-forma-djr-micro text-grey4 tracking-tighter">
							Picnic Pubcast
						</h1>
					</div>
				</div>
			);
		};

		const Bubble1 = () => {
			return (
				<div className="flex justify-center items-center h-1/2 !z-20">
					<div className="relative bg-white rounded-full px-2 py-2 flex gap-2 items-center z-20">
						<div className="absolute p-2 md:-top-3.5 md:-right-2 md:rotate-0 -bottom-5 right-6 scale-150 -scale-x-150 md:scale-100">
							<Image
								src="/assets/tenyears/img/bubble-point.png"
								alt="Bubble point"
								width={0}
								height={0}
								className="w-6 h-6 object-fit"
							/>
						</div>
						{bubble1.img && (
							<div className="flex">
								{bubble1.img.map(
									(img, index) =>
										img && (
											<div
												key={index}
												className={`aspect-square w-10 h-10 -mr-2 ${index >= 2 ? "hidden md:block" : ""}`}
											>
												<Image
													src={img}
													alt={`Participant ${index + 1}`}
													width={0}
													height={0}
													className="rounded-full w-full h-full object-cover"
												/>
											</div>
										)
								)}
							</div>
						)}
						<p
							className="leading-tight px-2 z-20"
							dangerouslySetInnerHTML={{
								__html: bubble1.text,
							}}
						/>
					</div>
				</div>
			);
		};

		const Bubble2 = () => {
			return (
				<div className="relative">
					<div className="absolute -top-4 left-0 right-0">
						<div className="flex justify-center items-center z-20">
							<p
								className="bg-white rounded-full py-2 px-6 mr-56 leading-tight z-20"
								dangerouslySetInnerHTML={{
									__html: bubble2.text,
								}}
							/>
						</div>
					</div>
					<Image
						src={bubble2.img}
						alt="Picnic Pubcast"
						width={0}
						height={0}
						className="mx-auto h-[400px] w-auto -mb-5 z-90"
					/>
				</div>
			);
		};

		const Bubble3 = () => {
			return (
				<div className="flex justify-center items-center h-3/4">
					<div className="relative bg-white rounded-full px-6 py-2 flex gap-2 items-center">
						<div className="absolute p-2 md:-top-3.5 md:-left-2 md:right-auto md:rotate-0 -top-3 right-12 scale-150 -scale-x-150 md:scale-100 md:-scale-x-100 z-0">
							<Image
								src="/assets/tenyears/img/bubble-point.png"
								alt="Bubble point"
								width={0}
								height={0}
								className="w-6 h-6 object-fit"
							/>
						</div>
						{bubble3.subtext && (
							<div
								className={`hidden md:block absolute -top-12 -right-8 bg-[#F3F6E9] rounded-full px-6 py-2 flex gap-2 items-center rotate-12 text-sm ${caveat.className}`}
							>
								<p
									className="leading-tight px-2"
									dangerouslySetInnerHTML={{
										__html: bubble3.subtext,
									}}
								/>
							</div>
						)}
						<Image
							src={bubble3.img}
							alt="Sparks"
							width={0}
							height={0}
							className="w-6 h-6 object-cover"
						/>
						<p
							className="leading-tight px-2 z-20"
							dangerouslySetInnerHTML={{
								__html: bubble3.text,
							}}
						/>
					</div>
				</div>
			);
		};

		const DesktopContent = () => {
			return (
				<div className="relative h-full w-full hidden md:block p-4">
					<div className="relative h-full w-full rounded-xl">
						<div className="absolute top-0 left-0 w-full h-2/3 z-40">
							<Logo />
						</div>
						<div className="flex justify-center items-center h-full">
							<div
								className="w-1/2 h-full bg-grey0"
								style={{
									backgroundImage:
										"url('/assets/tenyears/img/squiggly_line5.png')",
									backgroundRepeat: "no-repeat",
									backgroundSize: "200% auto",
									backgroundPosition: "top left",
									"--radius": "25px",
									WebkitMaskImage:
										"radial-gradient(circle at center, black 100%, black 100%), linear-gradient(black, black)",
									WebkitMaskComposite: "xor",
									maskImage:
										"radial-gradient(circle at center, black 100%, black 100%), linear-gradient(black, black)",
									maskComposite: "include",
									borderRadius: "var(--radius)",
									WebkitMask:
										"radial-gradient(circle at center, black 100%, black 100%), linear-gradient(black, black)",
									mask: "radial-gradient(circle at center, black 100%, black 100%), linear-gradient(black, black)",
								}}
							>
								<div className="z-20 h-full flex justify-between flex-col z-30 relative">
									<Bubble1 />
									<Bubble2 />
								</div>
							</div>
							<div
								className="z-30 w-1/2 h-full flex justify-between flex-col bg-grey0 justify-between"
								style={{
									backgroundImage:
										"url('/assets/tenyears/img/squiggly_line5.png')",
									backgroundRepeat: "no-repeat",
									backgroundSize: "200% auto",
									backgroundPosition: "top right",
									"--radius": "25px",
									WebkitMaskImage:
										"radial-gradient(circle at center, black 100%, black 100%), linear-gradient(black, black)",
									WebkitMaskComposite: "xor",
									maskImage:
										"radial-gradient(circle at center, black 100%, black 100%), linear-gradient(black, black)",
									maskComposite: "include",
									borderRadius: "var(--radius)",
									WebkitMask:
										"radial-gradient(circle at center, black 100%, black 100%), linear-gradient(black, black)",
									mask: "radial-gradient(circle at center, black 100%, black 100%), linear-gradient(black, black)",
								}}
							>
								<div className="bg-white absolute h-56 top-1/2 right-0 w-10 z-20"></div>
								<div className="flex-1 w-full relative flex items-end justify-center">
									<div className="bg-grey0 absolute h-56 bottom-0 right-0 w-20 z-20 rounded-xl"></div>
									<Bubble3 />
								</div>
								<div className="w-full">
									<PlayerInfo />
								</div>
							</div>
						</div>
					</div>
				</div>
			);
		};

		const MobileContent = () => {
			return (
				<div className="relative block md:hidden p-3">
					<div
						className="relative h-full w-full bg-grey1 pt-24 pb-3 px-3 rounded-xl"
						style={{
							...backgroundStyle,
							mask: "none",
						}}
					>
						<div className="flex flex-col gap-8 justify-between h-full">
							<Bubble1 />
							<Logo />
							<Bubble3 />
							<div className="mt-24">
								<PlayerInfo />
							</div>
						</div>
					</div>
				</div>
			);
		};

		return (
			<>
				<DesktopContent />
				<MobileContent />
			</>
		);
  };

	return (
		<>
			<div className="bg-white h-full md:h-[90vh] md:min-h-[800px] w-full relative overflow-hidden">
				<div
					className={`absolute inset-0 transition-opacity duration-500 ease-in-out bg-white px-12 py-3`}
				/>
				{/* Content */}
				<PodcastContent />
			</div>
			<PodcastList />
			<div></div>
		</>
	);
};

