"use client";
import Typewriter from "typewriter-effect/dist/core";
import { useEffect, useRef } from "react";
import isOnScreen from "@/shared/utils/isOnScreen";

interface TextBlockProps {
	data: {
		copy: {
			title: string;
			subtitle: string;
		};
	};
}

const TextBlock: React.FC<TextBlockProps> = ({ data }) => {
	const { copy, animation } = data;
	const { title, subtitle } = copy;
	const subHeadingRef = useRef<HTMLParagraphElement | null>(null);
	const isVisible = isOnScreen(subHeadingRef, true);

	const cursorDisappear = () => {
		if (typeof document !== "undefined") {
			// @ts-ignore
			document.querySelector(".Typewriter__cursor").style.display =
				"none";
		}
	};

	const strikeThrough = () => {
		document
			.querySelector(".striped")
			?.classList.add("picnic-underline-animated");
	};

	const subHeadingAppear = () => {
		if (subHeadingRef.current) {
			subHeadingRef.current.style.opacity = "1";
		}
	};

	useEffect(() => {
		if (animation) {
			const typewriterElement = document.querySelector(".Typewriter");
			const typewriter = new Typewriter(typewriterElement, {
				autoStart: true,
				delay: 100,
			});
			typewriter.start();
			if (isVisible) {
				typewriter
					.start()
					.typeString(title)
					.callFunction(cursorDisappear)
					.callFunction(strikeThrough)
					.pauseFor(1000)
					.callFunction(subHeadingAppear);
			} else {
				typewriter.stop();
			}
		} else {
			document
				.querySelector(".striped")
				?.classList.add("picnic-underline-animated");
		}
	}, [isVisible, title]);

	return (
		<div className="text-center text-grey5 py-8 md:py-16 md:px-8 px-6 text-balance">
			<h2 className="md:text-6xl text-4xl font-extrabold">
				<div className="absolute left-0 right-0">
					<div className="Typewriter" />
				</div>
				<div
					className={`${animation ? "opacity-0" : "opacity-100"} pb-4 [&_*]:font-forma-djr-display`}
				>
					<div dangerouslySetInnerHTML={{ __html: title }} />
				</div>
			</h2>
			<p
				ref={subHeadingRef}
				className={`${animation ? "opacity-0" : "opacity-100"} transition-opacity duration-[2000ms]`}
				dangerouslySetInnerHTML={{ __html: subtitle }}
			/>
		</div>
	);
};

export default TextBlock;
