"use client";
import { useEffect, useState, useRef, RefObject } from "react";

export default function isOnScreen(
	ref: RefObject<HTMLElement>,
	triggerOnce: boolean = false
) {
	const observerRef = useRef<IntersectionObserver | null>(null);
	const [isOnScreen, setIsOnScreen] = useState(false);

	useEffect(() => {
		observerRef.current = new IntersectionObserver(([entry]) => {
			if (entry.isIntersecting) {
				setIsOnScreen(true);
				if (triggerOnce) {
					observerRef.current.disconnect();
				}
			} else if (!triggerOnce) {
				setIsOnScreen(false);
			}
		});
	}, [triggerOnce]);

	useEffect(() => {
		if (ref.current) {
			observerRef.current.observe(ref.current);
		}

		return () => {
			observerRef.current.disconnect();
		};
	}, [ref, triggerOnce]);

	return isOnScreen;
}
