import React, { useEffect, useRef, useState } from "react";
import {
	getMarketandLang,
	buildLocale,
	newLocaleURL,
	getFlagEmoji,
} from "../../utils";
import { isMobile } from "../../hooks";
import {
	submenuSlideIn,
	ExitSubmenuButton,
	NavContainer,
} from "../Header/partials";
import { LanguageSwitcher, Country } from "./LanguageSwitcher.interfaces";
import Link from "next/link";
import {
	Check,
	KeyboardArrowRight,
	KeyboardArrowDown,
} from "@mui/icons-material";
import { APP_SITE, GLOBAL_PAGE_SITES } from "../../config/environment";

const RenderLanguageSwitcherComponent = ({
	texts,
	countries,
	textShadow,
	pageArgs,
	translations,
	enabled
}: LanguageSwitcher) => {
	const mobileView = isMobile();
	const locale = buildLocale(pageArgs);
	const lang = pageArgs?.locale?.language ?? "";
	const langText = texts && (texts[lang] ?? texts["en"]);
	const [langSwitcherActive, setLangSwitcherActive] = useState(false);
	const langSwitcherRef = useRef(null);
	const global = GLOBAL_PAGE_SITES.includes(pageArgs.application);

	useEffect(() => {
		if(global && APP_SITE == "jobs" && typeof translations == "object" && Object.keys(translations).length > 0) {
			if(!Object.keys(translations).includes(locale) || translations[locale] == false) {
				const firstLang = Object.keys(translations).find(key => translations[key] !== false && translations[key] != undefined);
				if(firstLang) {
					window.location.href = newLocaleURL(
						"global",
						firstLang,
						pageArgs
					);
				}
			}
		}
	}, [locale, translations])
	

	if (!countries || Object.keys(countries).length <= 1 || enabled == false) {
		return <></>;
	}
	if (countries && locale) {
		const marketandLang = getMarketandLang(locale);

		const languageDisplayMap = {
			en: mobileView ? "English" : "EN",
			de: mobileView ? "German" : "DE",
			fr: mobileView ? "French" : "FR",
			nl: mobileView ? "Dutch" : "NL",
		};

		const getLocaleLang = (
			selectedLocale: string,
			selectedLang: string
		) => {
			if (GLOBAL_PAGE_SITES.includes(pageArgs.application)) {
				return selectedLang;
			} else {
				return selectedLocale == selectedLang
					? selectedLocale
					: selectedLocale + "-" + selectedLang;
			}
		};

		const enableLangSwitcher = async (e) => {
			e.preventDefault();
			await setLangSwitcherActive(true);
			if (mobileView) {
				setTimeout(() => {
					submenuSlideIn(langSwitcherRef.current);
				}, 10);
			}
		};

		const RenderLanguages = (
			localeCode,
			languages,
			renderLangFlags = false
		) => {
			return Object.entries(languages).map(
				([langCode, language]: [string, string]) => {
					if (translations[langCode] !== false) {
						return (
							<button
								key={langCode}
								className={`block w-full py-1 text-left text-base hover:bg-grey1 flex flex-wrap ${
									getLocaleLang(localeCode, langCode) ===
									locale
										? "text-green1"
										: ""
								}`}
								onClick={() => {
									window.location.href = newLocaleURL(
										localeCode,
										langCode,
										pageArgs
									);
								}}
							>
								{renderLangFlags && getFlagEmoji(langCode)}
								<div className={"flex-1 pl-2"}>
									{language}
									{getLocaleLang(localeCode, langCode) ===
										locale && <Check className={"pl-2"} />}
								</div>
							</button>
						);
					}
				}
			);
		};

		const LanguageSwitcherContent = () => {
			return (
				<>
					{Object.entries(countries).map(
						([localeCode, country]: [string, Country]) => {
							if (global && localeCode == "global") {
								return (
									<div
										key={localeCode}
										className="flex flex-wrap gap-2 border-grey1 px-4 [&:not(:last-child)]:border-b [&:not(:last-child)]:pb-3"
									>
										<div className={"flex-1"}>
											{RenderLanguages(
												localeCode,
												country.lang,
												true
											)}
										</div>
									</div>
								);
							} else {
								return global ? null : (
									<div
										key={localeCode}
										className="flex flex-wrap gap-2 border-grey1 px-4 [&:not(:last-child)]:border-b [&:not(:last-child)]:pb-3"
									>
										{getFlagEmoji(localeCode)}
										<div className={"flex-1"}>
											<div className={"text-grey5"}>
												{country.name}
											</div>
											{RenderLanguages(
												localeCode,
												country.lang
											)}
										</div>
									</div>
								);
							}
						}
					)}
				</>
			);
		};

		return (
			<div
				className={
					"border-t border-grey1 pt-8 text-[1.3rem] font-normal md:border-0 md:pt-0 md:text-base md:font-semibold"
				}
			>
				<Link
					className="flex w-full items-center justify-between py-2"
					onClick={mobileView && enableLangSwitcher}
					onMouseEnter={!mobileView && enableLangSwitcher}
					href={"#"}
					prefetch={false}
				>
					<div
						className={`flex gap-2 pr-2 ${
							textShadow && "shadow-black text-shadow"
						}`}
					>
						{getFlagEmoji(marketandLang.language)}
						{languageDisplayMap[marketandLang.language]}
					</div>
					{!mobileView ? (
						<KeyboardArrowDown
							className={
								"!transition [div:hover>a>&]:-scale-y-100"
							}
						/>
					) : (
						<KeyboardArrowRight className={"!text-[1.75rem]"} />
					)}
				</Link>
				{langSwitcherActive &&
					(!mobileView ? (
						<div className="absolute hidden w-auto pt-7 font-normal text-grey3 [div:hover>&]:block">
							<div className="flex w-max flex-col gap-3 rounded bg-white py-5 shadow">
								{!global && langText && (
									<div
										className={
											"border-b border-grey1 px-4 pb-5"
										}
									>
										{langText}
									</div>
								)}
								<LanguageSwitcherContent />
							</div>
						</div>
					) : (
						<NavContainer
							className={"text-grey3"}
							ref={langSwitcherRef}
							mobileView={mobileView}
							isConsumer={pageArgs.application == "consumer"}
						>
							<ExitSubmenuButton
								text={langText}
								_ref={langSwitcherRef}
							/>
							<LanguageSwitcherContent />
						</NavContainer>
					))}
			</div>
		);
	} else {
		return <></>;
	}
};

export default RenderLanguageSwitcherComponent;
