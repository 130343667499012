import Image from 'next/image'
import "./awards.css"
import { Confetti } from '../components/Confetti'
import { htmlToReactParser } from '@/shared/utils'
import RenderMediaComponent from '@/shared/components/Media'
import { FAQ } from '../components/FAQ'
import { formaDJRDisplay } from '@/sites/events/utils/fonts'
import "./shared.css";
import NominationForm from '../components/NominateForm'
import AwardsTrophyText from '../components/AwardsTrophyText'

export const nominateCards: Record<string, any> = {
	"serve-families": {
		title: "Serve<br>Families",
		title_raw: "Serve Families",
		image: "/assets/tenyears/img/nominate-image-1.jpg",
		description: `Who is the ultimate friendly Milkman and a true customer champion? This award goes to the colleague or team with sky-high standards, who solves problems like a pro, always does what’s right for the customer, and contributes to delivering Picnic-perfect service every single time.`,
		cards: true,
	},
	"create-the-future": {
		title: "Create The<br>Future",
		title_raw: "Create The Future",
		image: "/assets/tenyears/img/nominate-image-2.jpg",
		description: `We’re on the hunt for a colleague bursting with fresh ideas, an entrepreneurial spirit, and the guts to take the lead and shake things up. Someone who can start with a blank canvas and create something extraordinary. Or maybe a team that’s always innovating, inspiring, and breaking new ground?`,
		cards: true,
	},
	"better-every-day": {
		title: "Better<br>Every Day",
		title_raw: "Better Every Day",
		image: "/assets/tenyears/img/nominate-image-3.jpg",
		description: `Who’s your go-to person for leveling up, giving constructive feedback, and adapting faster than you can say improvement? This award celebrates the colleague or team who moves quickly, embraces change, and has autonomy and accountability. They know how to focus and get things done.`,
		cards: true,
	},
	"the-extra-smile": {
		title: 'The<br>Extra Smile',
		title_raw: 'The Extra Smile',
		image: '',
		description: `We’re all about going the extra mile and having fun while building something amazing together. That’s why we’re celebrating the most fun team full of hungry, passionate, and talented people. For this prize it’s all about showing off your team’s best photo. Snap a pic, send it in, and tell us why your team deserves to win`,
		cards: false
	}
};

export const AwardsPage = (session: any) => {
	return (
		<div className="min-h-screen bg-white font-sans">
			{/* Hero Section */}
			<section className="bg-[#F8F5F2] relative py-10 md:py-32 md:pl-20 flex items-center">
				<Image
					src="/assets/tenyears/img/squiggly_line3.png"
					alt="line"
					width={1440}
					height={732}
					className="absolute transform bottom-0 right-0"
				/>
				<AwardsTrophyText />
			</section>

			{/* Introduction Section */}
			<section className="py-16 md:py-24 relative">
				<Confetti count={25} />
				<div
					className="container mx-auto flex flex flex-col lg:flex-row justify-between items-center w-full px-10"
					style={{ zIndex: 40, position: "relative" }}
				>
					<div className="w-full lg:w-1/2 pr-0 md:pr-8 mb-8 md:mb-0">
						<h2 className="md:text-3xl text-xl text-grey5 mb-4 mb-7">
							Introducing the
						</h2>
						<h1
							className="md:text-8xl text-6xl font-bold text-grey5"
							style={{ lineHeight: 0.8, letterSpacing: "-3px" }}
						>
							Best
						</h1>
						<h1
							className="md:text-8xl text-6xl font-bold text-grey5"
							style={{ lineHeight: 0.8, letterSpacing: "-3px" }}
						>
							Milkman
						</h1>
						<h1
							className="md:text-8xl text-6xl font-bold text-red1 picnic-underline relative"
							style={{ lineHeight: 0.8, letterSpacing: "-3px" }}
						>
							Awards
						</h1>
					</div>
					<div
						className="w-full lg:w-1/2 relative flex flex-col justify-center pt-14 lg:pt-0"
						style={{ height: "100%" }}
					>
						<p className="text-lg text-grey4 text-pretty">
							With the Best Milkman Awards, we celebrate what
							makes Picnic extraordinary: <b>our people!</b>
							<br />
							<br />
							The awards celebrate the very best of Picnic,
							individuals, teams, and projects that truly embody
							our <b>Milkman Beliefs.</b> Nominees can come from
							every corner of Picnic, across all locations, roles,
							and levels. They have made a positive impact, driven
							us forward and inspired us.
							<br />
							<br />
							The Milkman jury will review all nominations and
							announce the finalists in September. Then, everyone
							will have the chance to vote for the winners, who
							will be honoured with a Best Milkman Award during
							our <b>10-year celebration in October</b>.
							<br />
							<br />
							<b>
								Make sure to nominate your colleagues before 31
								August 2025
							</b>
						</p>
					</div>
				</div>
			</section>

			{/* Nominate Section */}
			<section className="bg-grey1">
				<div className="flex flex-col md:flex-row w-full">
					{Object.values(nominateCards).map((item, index) => {
						if (!item.cards) return null;
						return (
							<div
								key={`nominate-card-${index}`}
								className="relative group overflow-hidden w-full"
							>
								<div className="relative overflow-hidden w-full h-[600px]">
									<Image
										src={item.image}
										alt={`Award - ${item}`}
										layout="fill"
										objectFit="cover"
										className="transition-all duration-300 group-hover:scale-105 brightness-50 group-hover:brightness-75"
									/>
									<div className="absolute inset-0 flex flex-col justify-center items-center p-6 text-white">
										<h1
											className="text-6xl font-extrabold mb-4 text-center mb-9"
											style={{ lineHeight: 1.3 }}
										>
											{htmlToReactParser(item.title)}
										</h1>
										<NominationForm
											session={session}
											category={
												Object.keys(nominateCards)[
													index
												]
											}
										/>
									</div>
								</div>
								<div
									className={`text-center p-14 md:py-24 h-full font-plus-jakarta-sans ${index % 2 !== 0 ? "bg-[#F8F5F2]" : "bg-white "}`}
								>
									<div className="text-balance max-w-xl m-auto">
										{item.description}
									</div>
								</div>
							</div>
						);
					})}
				</div>
			</section>

			{/* Extra Smile Section */}
			<section className="bg-grey1">
				<div className="flex flex-col md:flex-row w-full">
					<div className="relative group overflow-hidden w-full">
						<div className="relative overflow-hidden w-full h-[670px]">
							<Image
								src={`/assets/tenyears/img/extra-smile-image.png`}
								alt={`Award`}
								layout="fill"
								objectFit="cover"
								className="transition-all duration-300 brightness-50"
							/>
							<div className="absolute inset-0 flex flex-col justify-center items-center p-6 text-white">
								<h1
									className="text-8xl font-extrabold mb-4 text-center mb-9"
									style={{ lineHeight: 1 }}
								>
									The Extra
									<br />
									Smile
								</h1>
							</div>
						</div>
						<div
							className={`py-14 font-plus-jakarta-sans bg-[#F8F5F2] text-center`}
						>
							<div className="max-w-3xl mx-auto text-center p-4">
								{htmlToReactParser(nominateCards["the-extra-smile"].description)}
							</div>
							<NominationForm
								text="Submit here!"
								session={session}
								category={"the-extra-smile"}
							/>
						</div>
					</div>
				</div>
			</section>

			{/* Celebration section */}
			<section className="relative">
				<div className="grid grid-cols-1 md:grid-cols-2 w-full h-auto">
					<div className="relative w-full h-full">
						<RenderMediaComponent
							data={{
								media_src: {
									desktop: {
										src: "/assets/tenyears/img/party-1.webp",
										type: "image",
										meta: {
											alt: "alt text",
											blurhash:
												"data:image/webp;base64,UklGRjAAAABXRUJQVlA4ICQAAACQAQCdASoEAAIAAgA0JZwAAudYugAA/vShPTMGsRJli3i4AAA=",
										},
									},
								},
							}}
							mobileView={false}
						/>
					</div>
					<div className="relative flex flex-col justify-center px-10 py-8 z-40">
						<Confetti count={15} />
						<h1
							className="text-5xl md:text-8xl font-extrabold text-grey5 font-forma-djr-display"
							style={{ zIndex: 40, lineHeight: 0.8 }}
						>
							Stay
						</h1>
						<h1
							className="text-5xl md:text-8xl font-extrabold text-grey5 font-forma-djr-display md:pb-4"
							style={{ zIndex: 40, lineHeight: 0.8 }}
						>
							tuned...
						</h1>
						<p
							className="text-lg text-grey4 font-plus-jakarta-sans py-6"
							style={{ zIndex: 40 }}
						>
							This October, we’ll also be celebrating the best
							Hubs and FCs.
							<br />
							They will be rewarded with the Golden Milkman Award.
							<br />
							More info to come!
						</p>
					</div>
				</div>
			</section>

			{/* FAQ Section */}
			<section className="py-16 relative bg-[#F8F5F2]">
				<div
					className="container mx-auto flex flex-col lg:flex-row justify-between items-start w-full px-4 lg:px-10"
					style={{ zIndex: 40, position: "relative" }}
				>
					<div className="w-full lg:w-1/2 mb-12 lg:mb-0">
						<div className="relative max-w-[600px] lg:m-auto lg:mx-0">
							<div className="relative z-[42] md:pt-20">
								<h1
									className="lg:text-6xl md:text-5xl sm:text-5xl font-bold text-grey5"
									style={{ lineHeight: 0.8 }}
								>
									Frequently
								</h1>
								<h1
									className="lg:text-6xl md:text-5xl sm:text-5xl font-bold text-grey5 mt-2"
									style={{ lineHeight: 0.8 }}
								>
									asked
								</h1>
								<h1
									className="lg:text-6xl md:text-5xl sm:text-5xl font-bold text-grey5 mt-2"
									style={{ lineHeight: 0.8 }}
								>
									questions
								</h1>
							</div>
							<div className="absolute top-[0px] left-[170px] lg:left-[220px] md:transform md:translate-y-1/4 md:translate-x-1/4 z-[41]">
								<Image
									src="/assets/tenyears/img/epv-1.png"
									alt=""
									width={285}
									height={285}
									className="w-48 sm:w-64 lg:w-[285px]"
								/>
							</div>
						</div>
					</div>
					<div className="w-full lg:pl-8 lg:w-1/2 relative flex flex-col justify-center">
						<FAQ />
					</div>
				</div>
			</section>
		</div>
	);
};
