import { CircularProgress } from '@mui/material'

interface SubmitButtonProps {
  isPending: boolean
}

export function SubmitButton({ isPending }: SubmitButtonProps) {
  return (
    <button
      type="submit"
      className={`text-white font-medium py-3 px-24 rounded-lg transition-colors ${
        isPending ? "bg-grey2" : "bg-red1 hover:bg-red2"
      }`}
      disabled={isPending}
    >
      {isPending ? (
        <>
          <CircularProgress size={12} color="inherit" />
          <span className="ml-2">Submitting...</span>
        </>
      ) : 'Submit'}
    </button>
  )
}
