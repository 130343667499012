import { plusJakartaSans } from '@/sites/events/utils/fonts'
import { SubmitButton } from './SubmitButton'

interface StandardNominationFormProps {
  session: any
  category: string
  loading: boolean
  onSubmit: (event: React.FormEvent<HTMLFormElement>) => Promise<void>
}

export function StandardNominationForm({ session, category, loading, onSubmit }: StandardNominationFormProps) {
  const disabledFieldClasses = "w-full px-4 py-3 rounded-lg bg-grey1 text-grey5 border border-gray-200 focus:border-gray-300 focus:ring-0 text-grey5 pointer-events-none"
  const enabledFieldClasses = "w-full px-4 py-3 rounded-lg bg-white border border-gray-200 focus:border-gray-300 focus:ring-0"

  return (
    <form onSubmit={onSubmit} className={`space-y-4 ${plusJakartaSans.className}`}>
      <input
        type="text"
        name="category"
        required
        hidden
        className={disabledFieldClasses}
        value={category}
      />
      <div className="space-y-2">
        <label htmlFor="name" className="block text-grey5 font-medium">
          Name
        </label>
        <input
          type="text"
          name="name"
          placeholder="Type your name"
          required
          className={disabledFieldClasses}
          value={session?.user?.name ?? ""}
        />
      </div>

      <div className="space-y-2">
        <label htmlFor="email" className="block text-grey5 font-medium">
          Email
        </label>
        <input
          type="text"
          name="email"
          placeholder="Type your email"
          required
          className={disabledFieldClasses}
          value={session?.user?.email ?? ""}
        />
      </div>

      <div className="space-y-2">
        <label htmlFor="nominee" className="block text-grey5 font-medium">
          I want to nominate
        </label>
        <input
          type="text"
          name="nominee"
          placeholder="Enter colleague, team or project"
          className={loading ? disabledFieldClasses : enabledFieldClasses}
        />
      </div>

      <div className="space-y-2">
        <label htmlFor="why" className="block text-grey5 font-medium">
          Why
        </label>
        <textarea
          name="reason"
          placeholder="I nominate them because..."
          rows={4}
          className={`${loading ? disabledFieldClasses : enabledFieldClasses} resize-none`}
        />
      </div>

      <div className="w-full text-center py-4">
        <SubmitButton isPending={loading} />
      </div>
    </form>
  )
}
