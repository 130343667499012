"use client";
import { useEffect } from "react";

export const AutoRefresh = ({interval}: {interval: number}) => {
    useEffect(() => {
        const intervalId = setInterval(() => {
            window.location.reload();
        }, interval * 1000);

        return () => clearInterval(intervalId);
    }, []);

    return null;
}