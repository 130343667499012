import React from "react";
import { PageContent } from "../../interfaces/IPage";
import { Container } from "../../styles/components";
import RenderPageBlock from "../PageBlocks";
import { StyledColumn, StyledRow } from "./row.styles";
import { Row, RowColumn } from "./row.interfaces";
import RenderMediaComponent from "../Media";
import { localeRouteInterface } from "../../utils";
import { KeyboardArrowDown } from "@mui/icons-material";
import "./row.styles.css";

type rowProps = {
	data: Row;
	module?: string;
	mobileView: boolean;
	pageArgs?: localeRouteInterface;
	extraComponents?: Record<string, React.FC>;
	context?: any;
};

const RenderRowComponent = ({
	data,
	module,
	mobileView,
	pageArgs,
	extraComponents,
	context,
}: rowProps) => {
	const { columns, advanced, styling } = data;
	const RenderComponents = (
		components: PageContent,
		index: number,
		extraParams: any = {}
	) => {
		if (components.type === "row") {
			return (
				<RenderRowComponent
					key={index}
					data={components.data as Row}
					mobileView={mobileView}
					pageArgs={pageArgs}
					extraComponents={extraComponents}
					context={context}
				/>
			);
		} else {
			return (
				<RenderPageBlock
					key={index}
					type={components.type}
					data={{ ...extraParams, ...components.data }}
					mobileView={mobileView}
					pageArgs={pageArgs}
					extraComponents={extraComponents}
					context={context}
				/>
			);
		}
	};

	const RenderColumn = (columnData: RowColumn, extraParams: any = {}) => {
		return (
			<>
				{columnData.components.map(
					(components: PageContent, index: number) => (
						<React.Fragment key={index}>
							{RenderComponents(components, index, extraParams)}
						</React.Fragment>
					)
				)}
			</>
		);
	};
	const device = styling?.device ?? false;
	const deviceClass = device == "mobile" ? "md:hidden" : "hidden md:block";
	return (
		<Container
			id={advanced?.anchor_name ? advanced.anchor_name : ""}
			className={`row ${device ? deviceClass : ""}`}
			$background_color={styling?.background_color}
		>
			{styling?.media_src && (
				<div
					className={`absolute w-full ${styling?.height ?? "h-full"}`}
				>
					<RenderMediaComponent
						data={{
							media_src: styling?.media_src,
							styling: {
								cover: true,
								media_sizing: {
									desktop: {
										width: "w-full",
									},
								},
								priority: styling?.media_src?.priority || false,
								video: {
									play_mode: "auto",
									muted: true,
								},
								bottom_shadow: styling?.bottom_shadow == true
							},
						}}
						mobileView={mobileView}
					/>
				</div>
			)}
			<div
				className={`${styling?.root_center !== false && "mx-auto"} ${
					styling?.padding
				} ${styling?.no_width_constraint !== true && "max-w-[1440px]"}`}
			>
				<StyledRow
					$mobileView={mobileView}
					$styling={styling}
					style={
						styling?.max_width
							? { maxWidth: styling?.max_width, margin: "auto" }
							: {}
					}
				>
					{columns && columns.map((column: RowColumn, index: number) => (
						<StyledColumn
							key={index}
							$styling={column.styling}
							style={{ color: "black" }}
						>
							{styling?.content_background == true ? (
								<div>
									{RenderColumn(column, {
										content_background:
											styling.content_background,
									})}
								</div>
							) : (
								RenderColumn(column)
							)}
						</StyledColumn>
					))}
				</StyledRow>
			</div>
			{styling?.scroll_indicator == true && (
				<div className="absolute bottom-0 left-1/2 transform -translate-x-1/2 text-white text-shadow-lg shadow-black">
					<KeyboardArrowDown
						className="animate-bounce"
						sx={{ fontSize: 48 }}
					/>
				</div>
			)}
		</Container>
	);
};

export default RenderRowComponent;
