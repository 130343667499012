'use client'

import { useState, useEffect } from 'react'
import { ThemeProvider } from '@mui/material/styles'
import Image from 'next/image'
import ConfettiEffect from './ConfettiEffect'
import { htmlToReactParser } from '@/shared/utils'
import { ExtraSmileForm } from './ExtraSmileForm'
import { StandardNominationForm } from './StandardNominationForm'
import { submitNomination } from '../../../actions/submit-nomination'
import { theme } from '../theme'
import { nominateCards } from '../../../pages/awards'

interface NominationModalProps {
  open: boolean
  onOpenChange: (open: boolean) => void
  category: string
  session: any
}

export function NominationModal({ open, onOpenChange, category, session }: NominationModalProps) {
  const [success, setSuccess] = useState(false)
  const [loading, setLoading] = useState(false)
  const [showConfetti, setShowConfetti] = useState(false)

  useEffect(() => {
    const handleEscape = (e: KeyboardEvent) => {
      if (e.key === 'Escape') onOpenChange(false)
    }

    if (open) {
      document.addEventListener('keydown', handleEscape)
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = 'unset'
    }

    return () => {
      document.removeEventListener('keydown', handleEscape)
      document.body.style.overflow = 'unset'
    }
  }, [open, onOpenChange])

  async function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault()
    setLoading(true)
  
    const formData = new FormData(event.currentTarget)
  
    formData.forEach((value, key) => {
      if (value instanceof File) {
        console.log(`FormData - ${key}:`, value.name, value.size, value.type)
      } else {
        console.log(`FormData - ${key}:`, value)
      }
    })
  
    const result = await submitNomination(formData)
  
    setLoading(false)
  
    if (result.success) {
      setSuccess(true)
      setShowConfetti(true)
      setTimeout(() => {
        onOpenChange(false)
        setSuccess(false)
        setShowConfetti(false)
      }, 5000)
    }
  }

  if (!open) return null

  return (
    <ThemeProvider theme={theme}>
      <div className="fixed inset-0 z-[99] overflow-y-auto">
        <div
          className="fixed inset-0 bg-black bg-opacity-50 transition-opacity"
          onClick={() => onOpenChange(false)}
        />

        <div className="flex min-h-full items-center justify-center p-4">
          <div
            className="relative w-full max-w-big rounded-lg bg-white shadow-DEFAULT transition-all"
            onClick={e => e.stopPropagation()}
          >
            {showConfetti && <ConfettiEffect />}
            <button
              onClick={() => onOpenChange(false)}
              className="absolute right-4 top-4 text-black hover:text-grey4 z-[99]"
            >
              <svg className="h-7 w-7" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
              </svg>
            </button>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              <div className="space-y-4 bg-green5 px-6 pt-12 rounded-lg md:rounded-r-none text-center text-grey5 relative overflow-hidden">
                <Image
                  src="/assets/tenyears/img/squiggly_line4.png"
                  alt="line"
                  width={1440}
                  height={732}
                  className="absolute top-0 left-0"
                />
                <div className="mb-6">
                  <h2 className="text-2xl font-semibold text-grey5 font-forma-djr-display">
                    You're nominating<br />someone for the category
                  </h2>
                </div>
                <h2 className="text-5xl font-extrabold text-green7 font-forma-djr-display pb-16">{nominateCards[category].title_raw}</h2>
                <div className="flex items-center space-x-2">
                  <p className="text-normal max-w-md m-auto font-source-sans-pro">{htmlToReactParser(nominateCards[category].description)}</p>
                </div>
                <Image
                  src="/assets/tenyears/img/form-trophies.png"
                  alt="line"
                  width={400}
                  height={732}
                  className="pl-16 pt-10 mx-auto"
                />
              </div>

              <div className="text-grey5 p-6 md:pt-14">
                {success ? (
                  <div className="h-full flex items-center justify-center">
                    <div className="text-center space-y-4">
                      <div className="text-green6 text-6xl">✓</div>
                      <p className="text-xl font-forma-djr-display">Nomination submitted successfully!</p>
                    </div>
                  </div>
                ) : (
                  category === "the-extra-smile" ? (
                    <ExtraSmileForm
                      session={session}
                      category={category}
                      loading={loading}
                      onSubmit={handleSubmit}
                    />
                  ) : (
                    <StandardNominationForm
                      session={session}
                      category={category}
                      loading={loading}
                      onSubmit={handleSubmit}
                    />
                  )
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </ThemeProvider>
  )
}

