"use client";

import { PieChartOutline } from "@mui/icons-material";
import { useEffect, useRef, useState } from "react";

interface ConfettiProps {
  count?: number;
  colors?: string[];
  dynamic?: boolean;
}

interface ConfettiPiece {
  id: number;
  x: number;
  y: number;
  rotation: number;
  rotationSpeed: number;
  scale: number;
  svg: string; // SVG image URL
  speed: number;
  opacity: number;
  movementAmount: number;
  color: string;
}

export function Confetti({
  count = 50,
  colors = ["#FDB347", "#B4D87C", "#A7A1D8", "#F45B69", "#FFD700"],
  dynamic = true,
}: ConfettiProps) {
  const MAX_SPEED = Number(Math.PI.toPrecision(3)) * 0.5;
  const MAX_SIZE = 1.5;
  const MIN_SIZE = .5;
  const CONFETTI_SVGS = [
    "/assets/tenyears/img/confetti-1.svg",
    "/assets/tenyears/img/confetti-2.svg",
    "/assets/tenyears/img/confetti-3.svg",
    "/assets/tenyears/img/confetti-4.svg",
    "/assets/tenyears/img/confetti-5.svg"
  ]
  const [confetti, setConfetti] = useState<ConfettiPiece[]>([]);
  const requestRef = useRef<number>();

  const generateConfetti = () => {
    return Array.from({ length: count }, (_, i) => ({
      id: i,
      x: Math.random() * 100,
      y: Math.random() * -100,
      rotation: Math.random() * 360,
      rotationSpeed: (Math.random() * 2 - 1) * 0.5,
      scale: (Math.random() * MAX_SIZE - MIN_SIZE) + MIN_SIZE,
      svg: CONFETTI_SVGS[Math.floor(Math.random() * CONFETTI_SVGS.length)],
      speed: Math.random() * 0.3 + 0.2,
      opacity: 1,
      movementAmount: Math.random() * 270 + 30,
    }));
  };

  const animateConfetti = () => {
    setConfetti((prevConfetti) =>
      prevConfetti.map((piece) => {
        if (piece.y > 100) {
          return {
            ...piece,
            y: Math.random() * -100,
            x: Math.random() * 100,
            movementAmount: Math.random() * 270,
            scale: (Math.random() * MAX_SIZE - MIN_SIZE) + MIN_SIZE,
          };
        }
        const speed = piece.speed > MAX_SPEED ? MAX_SPEED : piece.speed;
        return {
          ...piece,
          y: piece.y + speed,
          rotation: (piece.rotation + piece.rotationSpeed) % 360,
        };
      })
    );
    requestRef.current = requestAnimationFrame(animateConfetti);
  };

  useEffect(() => {
    setConfetti(generateConfetti());
    requestRef.current = requestAnimationFrame(animateConfetti);

    return () => {
      if (requestRef.current) {
        cancelAnimationFrame(requestRef.current);
      }
    };
  }, []);

  return (
    <div className="absolute inset-0 overflow-hidden pointer-events-none" style={{zIndex: 10}}>
      {confetti.map((piece) => {
        const rotation = piece.rotation + piece.movementAmount * 18;
        const transform = dynamic ? `rotateX(${rotation}deg) rotateY(${rotation}deg) rotateZ(${rotation}deg)` : `rotate(${rotation}deg)`
        return (
        <img
          key={piece.id}
          src={piece.svg}
          alt="confetti"
          style={{
            position: "absolute",
            left: `${piece.x}%`,
            top: `${piece.y}%`,
            transform: `${transform} scale(${piece.scale}) translateX(${Math.sin(piece.movementAmount) * 180}px)`,
            opacity: piece.opacity,
            width: "auto",
            height: "auto",
          }}
        />
      )})}
    </div>
  );
}
