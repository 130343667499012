import { createTheme } from '@mui/material/styles'

export const theme = createTheme({
  palette: {
    primary: {
      main: '#E1171E', // red1
    },
    secondary: {
      main: '#B40117', // red2
    },
  },
  typography: {
    fontFamily: 'Source Sans Pro, Roboto, sans-serif',
  },
})

