import RenderJobFormModalComponent from "./partials/JobFormModal"
import RenderRowComponent from "@/shared/components/Row";
import Navigation from "./partials/Navigation";
import { Container } from "@/shared/styles/components/container";
import { slugify } from "@/shared/utils/string";
import { Row } from "@/shared/components/Row/row.interfaces";
import { localeRouteInterface } from "@/shared/utils";
import { APP_URL } from "@/shared/config";
import { PageContent } from "@/shared/interfaces";
import './jobcontent.css';


const parseContentIntoHeadings = (content: any) => {
  content = content.map((row) => {
    const regex = /<h[1-6]\b[^>]*>(.*?)<\/h[1-6]>/g
    const match = row.data && JSON.stringify(row.data).match(regex);
    if(match) {
      const heading = match[0].replace(/<[^>]+>/g, '').replace('&nbsp;', '').trim();
      if(heading === '') return row;
      row.heading = heading;
      row.heading_id = slugify(row.heading);
      if(!row?.data?.advanced) {
        row.data.advanced = {};
      }
      row.data.advanced.anchor_name = slugify(row.heading_id);
    }
    return row;
  });
  
  return content;
};

const injectVariableLinks = async (content: Row, pageArgs: localeRouteInterface) => {
  const variables = [...JSON.stringify(content).matchAll(/<span\s+data-variable=["']([^"']+)["'][^>]*>(.*?)<\/span>/g)].map(match => ({
    variable: match[1],
    content:  match[2],
    full: match[0]
  }));
  
  for (const variable of variables) {   
    const page = await pageExists(pageArgs, variable.content);
    const vacancyRoute = page ? `${APP_URL}/${slugify(variable.content)}` : '';
    const buttonClass = `inline-block duration-200 ease-in-out active:animate-[click_200ms_ease-in-out] mb-2 mt-2 lg:mb-0 \
                         px-8 py-2.5 rounded-full min-w-fit h-11 bg-transparent \
                         text-black border-[1px] \
                         ${vacancyRoute 
                          ? 'border-green1 hover:bg-green1 hover:text-white'
                          : 'border-grey hover:cursor-default grayscale-0'}`;

    const replacement = vacancyRoute 
      ? `<a class='${buttonClass}' href='${vacancyRoute}'>${variable.content}</a>` 
      : `<span class='${buttonClass}'>${variable.content}</span>`;

    content = JSON.parse(JSON.stringify(content).replace(variable.full, replacement));
  };
  
  return content;
}

const swapContentMedia = (content: PageContent) => {
  const videoRegexMap = [/https?:\/\/[^\s]+?\.(mp4|webm|ogg)(\?[^ \n]*)?/i, /https?:\/\/(?:www\.)?(youtube\.com\/(?:embed\/|watch\?v=|v\/|)|youtu\.be\/|vimeo\.com\/)([\w]+)/gi];
  const imageUrlRegex = /https?:\/\/[^\s]+?\.(jpg|jpeg|png|gif)(\?[^ \n]*)?/i;
  
  let videoUrl:String[] = [];
  videoRegexMap.forEach(regex => videoUrl = JSON.stringify(content).match(regex) || videoUrl);
  
  if (videoUrl.length) {
    content = JSON.parse(JSON.stringify(content).replace(/<iframe\b[^>]*>(.*?)<\/iframe>/gi, '')
      .replace(imageUrlRegex, videoUrl[0].replace('watch?v=', 'embed/').replace('v/', 'embed/'))
      .replace('"image"', '"video"'))
    const index = content[0].data.columns.length;
    if(content[0].data.columns[index-1].components[0].data.styling.video) {
      content[0].data.columns[index-1].components[0].data.styling.video.isIframe = true;
    }
  }
  return content;
}

const injectRecruiterInfo = (content, job) => {
  if(job.data.data.recruiter) {
    const { name, image_src, contacts } = job.data.data.recruiter;
    const recruiter = content.find(row => row.block_name === 'socials').data.columns[0].components[0].data;
    recruiter.subheading = `<p>${name} - Recruiter</p>`;
    recruiter.media.desktop.src = image_src ? image_src : recruiter.media.desktop.src; 
    recruiter.linkedin = contacts.linkedin ? contacts.linkedin : recruiter.linkedin
    recruiter.email = contacts.email ? contacts.email : recruiter.email
  }
}

const changeFirstSectionSize = (content: any): any => {
  const firstSection = content.find(row => JSON.stringify(row.data.columns.includes('"type":"text"')))
  firstSection.data.columns[0].components[0].data.content = firstSection.data.columns[0].components[0].data.content.replace(/<p>/g, '<p style="font-size:24px;">');
}

async function pageExists(pageArgs, route) {
  const slug = slugify(route);

  const url = `${APP_URL}/${pageArgs.locale.language}/${slug}`;
  const page = await fetch(url, { method: 'HEAD', cache: "no-store" }).then(res => res.ok);
  return page;
}

const syncRecruitmentSteps = (content: PageContent, job: any) => {
  if(!job.data.data.recruitment_steps) return;
  const steps = job.data.data.recruitment_steps.split(',');
  const recruitmentStepsIndex = content.findIndex(r => r.block_name === 'card-slider' )

  const generateSteps = (steps:any) => {
    return steps.map((step: any) => ({
      type: "card",
      data: {
        content: `<p>${step}</p>`,
        media_src: {
          desktop: {
            src: "https://d1gr3r269tafbs.cloudfront.net/nextjs/jobs/avo-animation-mobile_1710773130.gif",
            type: "image",
            meta: {
              blurhash: "data:image/webp;base64,UklGRjAAAABXRUJQVlA4ICQAAACQAQCdASoEAAIAAgA0JZwAAudYugAA/vShPTMGsRJli3i4AAA="
            }
          },
          mobile: {
            src: "",
            type: "image"
          }
        },
        iconned_content: [],
        buttons: [],
        styling: {
          no_padding: true,
          sizing: "lg",
          media_sizing: {
            desktop: {
              width: "w-full",
              height: "md:h-52 h-36"
            }
          },
          image_position: "right"
        }
      }
    }));
  }

  const updatedSlider = {
    type: "slider",
    data: {
      items: generateSteps(steps),
      styling: {
        itemsPerRow: {
          xs: 1,
          sm: 1,
          md: 3,
          lg: 3
        },
        itemSizing: {
          desktop: "auto",
          mobile: "auto"
        },
        hasArrows: true,
        numbered: true
      }
    }
  };

  content[recruitmentStepsIndex].data.columns[0].components[1] = updatedSlider

  return;
}

const RenderJobContent = async ({ data, mobileView, pageArgs, context }) => {
  const templateOverride = pageArgs.query.templateOverride || false;
  let content, hero;
  
  if(templateOverride){
    // swap form heading with job name
    data.form[0].data.columns[0].components[0].data.settings.header_text = pageArgs.extra.job.data.name
    content = swapContentMedia(parseContentIntoHeadings(data.content).filter((row, index) => row.block_name !== 'job_form'));
    injectRecruiterInfo(content, pageArgs.extra.job);
    syncRecruitmentSteps(content, pageArgs.extra.job);
    changeFirstSectionSize(content.slice(1))
    hero = await injectVariableLinks(content[0], pageArgs);
  }
  
  
  return (
    templateOverride ? 
    (
      <div className="jobcontent">
        <RenderRowComponent key={hero.block_name} data={hero.data} mobileView={mobileView} pageArgs={pageArgs} context={context}/>
        <Container className="grid grid-cols-6 row md:px-26 max-w-[1440px] mx-auto" $background_color="transparent">
          <div className={`fixed bg-white flex flex-col-reverse drop-shadow-faded pt-4 pb-2 w-full left-0 bottom-0 z-[999] items-center\
            md:drop-shadow-none md:flex-col md:pt-0 md:bg-transparent md:w-auto md:!sticky md:col-span-1 md:top-14 md:mt-10 md:items-start`}
            style={{height: 'fit-content'}}>
            <RenderJobFormModalComponent data={data.form} mobileView={false} context={context} />
            <Navigation content={content.slice(1)} mobileView={mobileView}/>
          </div>
          <div className="col-span-full md:col-span-5">
            {content.slice(1).map((el, index) => {
              el.data.styling = {"padding": "tighter"};
              
              return <RenderRowComponent key={index} data={el.data} mobileView={mobileView} pageArgs={pageArgs} context={context}/>
            })}
          </div>
        </Container>
      </div>
    ) : (
      <>
        {data.content.map((el, index) => {
          return <RenderRowComponent key={index} data={el.data} mobileView={mobileView} pageArgs={pageArgs} context={context}/>
        })}
      </>
    ) 
  )
}

export default RenderJobContent