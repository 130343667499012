import Image from "next/image";
import React from "react";

export const NavigationButtons = ({ sliderNext, sliderPrev }) => {
	return (
		<div className="absolute right-0 max-[428px]:-top-32 -top-1/2 md:-top-20 flex gap-6 z-50">
			<NavigationArrow direction="prev" onClick={() => sliderPrev()} />
			<NavigationArrow direction="next" onClick={() => sliderNext()} />
		</div>
	);
};
export const NavigationArrow = ({ direction, onClick }) => {
	return (
		<button
			className={`arrow-button hover:opacity-70 transition ${
				direction == "prev" && "rotate-180"
			}`}
			onClick={onClick}
		>
			<Image
				width={56}
				height={56}
				src={"/assets/jobs/img/arrow-btn.png"}
				alt={`${direction} slider arrow`}
			/>
		</button>
	);
};
