import { useState } from 'react'
import Image from 'next/image'
import { Box } from '@mui/material'
import { plusJakartaSans } from '@/sites/events/utils/fonts'

interface ExtraSmileFormProps {
  session: any
  category: string
  loading: boolean
  onSubmit: (event: React.FormEvent<HTMLFormElement>) => Promise<void>
}

export function ExtraSmileForm({ session, category, loading, onSubmit }: ExtraSmileFormProps) {
  const [selectedFile, setSelectedFile] = useState<File | null>(null)
  const [previewUrl, setPreviewUrl] = useState<string | null>(null)

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0]
    if (file) {
      setSelectedFile(file)
      const reader = new FileReader()
      reader.onloadend = () => {
        setPreviewUrl(reader.result as string)
      }
      reader.readAsDataURL(file)
    }
  }

  const disabledFieldClasses = "w-full px-4 py-3 rounded-lg bg-grey1 text-grey5 border border-gray-200 focus:border-gray-300 focus:ring-0 text-grey5 pointer-events-none"
  const enabledFieldClasses = "w-full px-4 py-3 rounded-lg bg-white border border-gray-200 focus:border-gray-300 focus:ring-0"

  return (
    <form onSubmit={onSubmit} className={`space-y-4 ${plusJakartaSans.className}`}>
      <input
        type="text"
        name="category"
        required
        hidden
        className={disabledFieldClasses}
        value={category}
      />
      <div className="space-y-2">
        <label htmlFor="name" className="block text-grey5 font-medium">
          Name
        </label>
        <input
          type="text"
          name="name"
          placeholder="Type your name"
          required
          className={disabledFieldClasses}
          value={session?.user?.name ?? ""}
        />
      </div>

      <div className="space-y-2">
        <label htmlFor="email" className="block text-grey5 font-medium">
          Email
        </label>
        <input
          type="text"
          name="email"
          placeholder="Type your email"
          required
          className={disabledFieldClasses}
          value={session?.user?.email ?? ""}
        />
      </div>

      <div className="space-y-2">
        <label htmlFor="photo" className="block text-grey5 font-medium">
          Upload Your Smile
        </label>
        <Box
          className="border-2 border-dashed border-grey3 rounded-lg p-4 text-center cursor-pointer hover:border-grey4 transition-colors"
          onClick={() => document.getElementById('photo-upload')?.click()}
        >
          <input
            id="photo-upload"
            type="file"
            accept="image/*"
            name="image"
            onChange={handleFileChange}
            className="hidden"
          />
          {previewUrl ? (
            <div className="relative w-full h-20 max-h-20">
              <Image
                src={previewUrl}
                alt="Preview"
                layout="fill"
                objectFit="contain"
              />
            </div>
          ) : (
            <p className="text-grey4">Click or drag to upload your smile photo</p>
          )}
        </Box>
      </div>

      <div className="space-y-2">
        <label htmlFor="why" className="block text-grey5 font-medium">
          Why This Smile?
        </label>
        <textarea
          name="reason"
          placeholder="Tell us about this smile..."
          rows={4}
          required
          className={`${loading ? disabledFieldClasses : enabledFieldClasses} resize-none w-full`}
        />
      </div>

      <div className="w-full text-center py-4">
        <button
          type="submit"
          className={`text-white font-medium py-3 px-24 rounded-lg transition-colors ${
            loading || !selectedFile ? "bg-grey2" : "bg-red1 hover:bg-red2"
          }`}
          disabled={loading || !selectedFile}
        >
          {loading ? 'Submitting...' : 'Submit Smile'}
        </button>
      </div>
    </form>
  )
}
