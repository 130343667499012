"use client"

import React, { useState } from 'react'
import { ChevronDownIcon } from 'lucide-react'

interface AccordionItemProps {
  question: string
  answer: string
}

const AccordionItem: React.FC<AccordionItemProps> = ({ question, answer }) => {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <div className="border-b border-[#E5E5E5] last:border-b-0 font-plus-jakarta-sans">
      <button
        className="flex justify-between items-center w-full py-6 text-left"
        onClick={() => setIsOpen(!isOpen)}
      >
        <span className="text-[#2D2D2D] text-xl font-bold">{question}</span>
        <ChevronDownIcon
          className={`w-6 h-6 text-[#2D2D2D] transition-transform duration-200 ${
            isOpen ? 'transform rotate-180' : ''
          }`}
        />
      </button>
      {isOpen && (
        <div className="pb-6">
          <p className="text-[#2D2D2D] text-lg">{answer}</p>
        </div>
      )}
    </div>
  )
}

const faqData = [
	{
		question: "How will we celebrate the Picnic 10-year milestone?",
		answer: "Over the past decade, Picnic has grown into a remarkable community of 20,000 people across 150 locations—from Paris to Hamburg. To ensure everyone can join in the fun, we’re hosting celebration events at each Picnic location this summer. Then, in October, to close the 10-year program we will have the centrally organized Best Milkman Awards.",
	},
	{
		question: "Who is invited to the Best Milkman Awards?",
		answer: "In October, we’re combining our business update and awards celebration into one exciting event! Invitations go out to all regular business update attendees, including central teams from each country and members of our operational leadership—Hub Leads, Ops Managers, Supervisors and FC Leads. Of course, the Best Milkman Award finalists will also join us for this special occasion!",
	},
	{
		question: "How do I nominate people?",
		answer: "Nominate through the forms above! Simply fill in the name of the person or team and share why they deserve to win. You’ve got a limited time, so don’t wait for a last-minute epiphany. Make sure to nominate your colleagues before 31 August 2025",
	},
	{
		question: "How will the winners be chosen?",
		answer: "Our Milkman jury carefully reviews every nomination and will announce the finalists in September. After that, it’s your turn! Everyone will get a chance to vote for the winners, who will be celebrated with a Best Milkman Award during our exciting 10-year anniversary event in October.",
	},
	{
		question: "What happens if I or my nominee wins?",
		answer: "If your nominee wins, they’ll get their moment in the spotlight at the awards ceremony and receive a prize or recognition. If you win, you’ll be the star of the show, receiving recognition, a shiny prize, and the pride of being Picnic’s finest. Expect to be adored by your colleagues forevermore.",
	},
];

export function FAQ() {
  return (
    <div className="w-full">
      {faqData.map((item, index) => (
        <AccordionItem key={index} question={item.question} answer={item.answer} />
      ))}
    </div>
  )
}

