import RenderPageBlock from "@/components/PageBlocks";
import RenderRowComponent from "@/components/Row";
import { PageContent, PageResponse } from "@/interfaces/IPage";
import { localeRouteInterface } from "@/utils/MarketandLanguage";
import React from "react";
import consumerComponentMap from "@/sites/consumer/components";
import jobsComponentMap from "@/sites/jobs/components";
import deeplinksComponentMap from "@/sites/deeplinks/components";

type mapBlockProps = {
	data: PageResponse["data"];
	context: any;
	serverIsMobileView: boolean;
	pageArgs?: localeRouteInterface;
};

export const compMapping = {
	mono: {},
	jobs: jobsComponentMap,
	consumer: consumerComponentMap,
	deeplinks: deeplinksComponentMap
};

const MapBlocks = ({
	data,
	context,
	serverIsMobileView,
	pageArgs,
}: mapBlockProps) => {
	const extraComponents = compMapping[
		pageArgs?.application || "mono"
	] as Record<string, React.FC>;

	return (
    <>
			{(data?.content || []).map((blocks: PageContent, index: number) => {
        if(blocks?.disabled !== true){
          if (blocks.type === "row") {
            return (
              <RenderRowComponent
                key={index}
                data={blocks.data}
                module={data.module}
                mobileView={serverIsMobileView}
                pageArgs={pageArgs}
                extraComponents={extraComponents}
                context={context}
              />
            );
          } else {
            return (
              <RenderPageBlock
                key={index}
                type={blocks.type}
                data={blocks.data}
                module={data.module}
                mobileView={serverIsMobileView}
                pageArgs={pageArgs}
                extraComponents={extraComponents}
                context={context}
              />
            );
          }
        }
			})}
		</>
	);
};

export default MapBlocks;
