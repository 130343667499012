'use client'

import { useState } from 'react'
import { ThemeProvider } from '@mui/material/styles'
import { theme } from './theme'
import { NominationModal } from './partials/NominationModal'

interface NominationFormProps {
  session: any
  category: string
  text?: string
}

export function NominationForm({ session, category, text = "Nominate now!" }: NominationFormProps) {
  const [open, setOpen] = useState(false)
  const existingNomination = false

  return !existingNomination ? (
    <ThemeProvider theme={theme}>
      <button
        className="bg-red1 mt-4 hover:bg-red2 text-white py-4 px-8 rounded-full text-xl shadow-lg transition duration-300 ease-in-out transform hover:scale-105"
        onClick={() => setOpen(true)}
      >
        {text}
      </button>
      <NominationModal
        open={open}
        onOpenChange={setOpen}
        category={category}
        session={session}
      />
    </ThemeProvider>
  ) : (
    <button
      className="bg-grey3 mt-4 text-white py-4 px-8 rounded-full text-xl shadow-lg transition duration-300 ease-in-out transform hover:scale-105"
      disabled
    >
      Already nominated
    </button>
  )
}

export default NominationForm

