'use client'

import React, { useEffect, useRef, useState } from 'react'
import Image from 'next/image'
import { formaDJRDisplay } from '@/sites/events/utils/fonts'

export default function AwardsHero() {
  const textContainerRef = useRef<HTMLDivElement>(null)
  const textContentRef = useRef<HTMLDivElement>(null)
  const trophyContainerRef = useRef<HTMLDivElement>(null)
  const trophyContentRef = useRef<HTMLDivElement>(null)
  const [textScale, setTextScale] = useState(1)
  const [trophyScale, setTrophyScale] = useState(1)

  useEffect(() => {
    const resizeObserver = new ResizeObserver((entries) => {
      for (let entry of entries) {
        if (entry.target === textContainerRef.current) {
            const containerWidth = entry.contentRect.width
            const contentWidth = textContentRef.current?.offsetWidth || 0
            setTextScale(Math.min(containerWidth / contentWidth, 1))
        } else if (entry.target === trophyContainerRef.current) {
          const containerWidth = entry.contentRect.width
          const contentWidth = trophyContentRef.current?.offsetWidth || 0
          setTrophyScale(Math.min(containerWidth / contentWidth, 1))
        }
      }
    })

    if (textContainerRef.current) {
      resizeObserver.observe(textContainerRef.current)
    }
    if (trophyContainerRef.current) {
      resizeObserver.observe(trophyContainerRef.current)
    }

    return () => resizeObserver.disconnect()
  }, [])

  return (
    <div className="mx-auto flex awards-hero w-full md:w-initial">
      <div ref={textContainerRef} className="x-years-text-container flex flex-col m-auto justify-center">
        <div
          ref={textContentRef}
          className="x-years-text"
          style={{
            transform: `scale(${textScale})`,
            transformOrigin: 'center left',
          }}
        >
          <div style={{maxWidth: "570px"}}>
          <p className="text-4xl font-plus-jakarta-sans pb-8">Celebrating</p>
          <p
            className={`big-title font-extrabold ${formaDJRDisplay.className}`}
            style={{ lineHeight: 0.8, letterSpacing: "-3px" }}
          >
            Picnic
          </p>
          <div
            className="relative inline-flex justify-center items-center big-title"
            style={{ lineHeight: 0.2 }}
          >
            <div className="relative flex items-center">
              <Image
                src="/assets/tenyears/img/balloons.png"
                alt=""
                width={300}
                height={300}
                style={{
                  width: "auto",
                  height: "300px",
                  position: "absolute",
                  left: "-130px",
                  top: "-250px",
                  transform: "rotate(-20deg)",
                }}
              />
              <span
                style={{
                  border: "10px solid white",
                  lineHeight: 1.1,
                  letterSpacing: "0px"
                }}
                className="text-8xl text-white bg-red1 rounded-full flex justify-center items-center border-white font-plus-jakarta-sans font-bold shadow-lg absolute -top-[28px] -left-10 px-5 py-8"
              >
                10
              </span>
              <span
                style={{
                  marginLeft: "115px",
                  zIndex: 99,
                  letterSpacing: "-3px",
                  paddingTop: "50px",
                }}
                className="font-extrabold font-forma-djr-micro"
              >
                Years
              </span>
            </div>
          </div>
          </div>
        </div>
      </div>
      <div
        ref={trophyContainerRef}
        className="relative x-years-trophy md:block hidden"
        style={{ paddingLeft: "20%", paddingTop: "100px" }}
      >
        <div
          ref={trophyContentRef}
          style={{
            transform: `scale(${trophyScale})`,
            transformOrigin: 'top left',
          }}
        >
          <div className="relative" style={{ zIndex: 99 }}>
            <div style={{ position: 'relative', width: '90%', marginTop: '20px', maxWidth: "600px" }}>
              <Image
                src="/assets/tenyears/img/trophies-hd.webp"
                alt=""
                width={400}
                height={400}
                style={{ width: "100%", zIndex: 30, position: "relative" }}
              />
              <div
                style={{
                  position: 'absolute',
                  top: '-30%',
                  left: '-35%',
                  width: '115%',
                  height: '100%',
                  zIndex: 15,
                }}
              >
                <Image
                  className="spin"
                  src="/assets/tenyears/img/trophy-spinner.png"
                  alt=""
                  width={200}
                  height={200}
                  style={{ width: "100%", height: "100%" }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

