'use client'

import React, { useState, useEffect, useRef } from "react";
import Image from 'next/image'
import { Menu, X } from 'lucide-react'
import { Link } from "@/shared/components/Link";
import { AnimatePresence, motion } from "framer-motion";

interface Page {
    name: string;
    path: string;
}

const pages: Page[] = [
	{ name: "Home", path: "/home" },
	{ name: "Milkman Beliefs", path: "/beliefs" },
	{ name: "Best Milkman Awards", path: "/best-milkman-awards" },
	{ name: "Pubcast", path: "/pubcast" },
];

const NavigationComponent: React.FC = ({ options }: any) => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const currentRoute = `/${options.args.route.join("/")}`;
    const [activeIndex, setActiveIndex] = useState(0);
    const [dimensions, setDimensions] = useState({ width: 0, x: 0 });
    const itemsRef = useRef<(HTMLSpanElement | null)[]>([]);

    const toggleMenu = () => setIsMenuOpen(!isMenuOpen);

    useEffect(() => {
      if (isMenuOpen) {
        document.body.style.overflow = 'hidden';
      } else {
        document.body.style.overflow = 'unset';
      }

      return () => {
        document.body.style.overflow = 'unset';
      };
    }, [isMenuOpen]);

    useEffect(() => {
      const index = pages.findIndex(page => page.path === currentRoute);
      setActiveIndex(index !== -1 ? index : 0);
    }, [currentRoute]);

    useEffect(() => {
      const activeItem = itemsRef.current[activeIndex];
      if (activeItem) {
        const parentLeft = activeItem.offsetParent?.getBoundingClientRect().left || 0;
        const itemRect = activeItem.getBoundingClientRect();
        setDimensions({
          width: itemRect.width,
          x: itemRect.left - parentLeft
        });
      }
    }, [activeIndex]);

    return (
		<header className="relative z-[99]">
			<div className="absolute top-4 md:top-7 left-5 md:left-20 z-20 cursor-pointer">
				<Link href="/" prefetch={true}>
					<Image
						src="/assets/tenyears/img/picnic-10-logo.png"
						alt="Logo"
						width={50}
						height={50}
					/>
				</Link>
			</div>

			{/* Hamburger menu for mobile */}
			<button
				className="lg:hidden absolute top-7 right-4"
				onClick={toggleMenu}
				aria-label="Toggle menu"
			>
				{isMenuOpen ? <X size={24} /> : <Menu size={24} />}
			</button>

			{/* Desktop navigation */}
			<nav className="hidden lg:block absolute top-4 md:top-8 left-1/2 transform -translate-x-1/2 z-20">
				<div className="bg-white rounded-full px-2 py-2">
					<ul className="flex items-center justify-center px-0">
						{pages.map((page) => (
							<li
								key={page.name}
								className="list-none whitespace-nowrap"
							>
								<Link href={page.path} prefetch={true}>
									<span
										className={`px-2 sm:px-3 md:px-4 py-1 rounded-full text-xs sm:text-sm md:text-base transition-colors duration-200 ${
											currentRoute === page.path
												? "bg-green5"
												: "hover:bg-gray-100"
										}`}
									>
										{page.name}
									</span>
								</Link>
							</li>
						))}
					</ul>
				</div>
			</nav>

			{/* Mobile navigation */}
			{isMenuOpen && (
				<nav className="lg:hidden fixed inset-0 bg-white z-[60] overflow-y-auto">
					<button
						onClick={() => setIsMenuOpen(false)}
						className="absolute right-7 top-7 text-black hover:text-grey4 z-[99]"
					>
						<svg
							className="h-7 w-7"
							fill="none"
							viewBox="0 0 24 24"
							stroke="currentColor"
						>
							<path
								strokeLinecap="round"
								strokeLinejoin="round"
								strokeWidth={2}
								d="M6 18L18 6M6 6l12 12"
							/>
						</svg>
					</button>
					<ul className="flex flex-col items-center justify-center min-h-full py-20 space-y-4">
						{pages.map((page) => (
							<li key={page.name} className="list-none">
								<Link
									href={page.path}
									prefetch={true}
									onClick={toggleMenu}
								>
									<span
										className={`px-4 py-2 rounded-full text-lg ${
											currentRoute === page.path
												? "bg-green5"
												: ""
										}`}
									>
										{page.name}
									</span>
								</Link>
							</li>
						))}
					</ul>
				</nav>
			)}
		</header>
	);
};

export default NavigationComponent;

