import { sendGTMEvent } from "@next/third-parties/google";
import { APP_URL } from "../../../config";
import {
	flattenObject,
	localeRouteInterface,
	unflattenObject,
} from "../../../utils";

export const handleSubmit = (
	formData,
	source,
	jobId,
	setLoading,
	setFormStatus,
	setError,
	args: localeRouteInterface,
	formId?: string
) => {
	window.dataLayer = window.dataLayer || [];
	// TODO
	// startLoadAnimation();
	setLoading(true);
	const bodyData = unflattenObject({
		...formData,
		job_id: jobId,
		...(source == "fountain" && {
			"data.utm_source": args.query.utm_source || "",
			"data.utm_medium": args.query.utm_medium || "",
			"data.utm_campaign": args.query.utm_campaign || "",
			"data.utm_content": args.query.utm_content || "",
			"data.utm_term": args.query.utm_term || "",
		}),
		...(source == "greenhouse" && {
			mapped_url_token: args.query.gh_src || "",
		}),
	});

	console.log("Form submitted", bodyData, formId);

	fetch(`${APP_URL}/api/forms/submission/${source}`, {
		method: "POST",
		body: JSON.stringify(bodyData),
	})
		.then((response) => response.json())
		.then((response) => {
			let email: string = "";
      let phoneNumber: string = "";
			let gtmValues: any = flattenObject(bodyData);
			gtmValues = Object.entries(gtmValues).reduce(
				(acc, [key, value]) => {
					if(key.includes("email")) {
						email = value as string;
					}
          			if(key.includes("phone")) {
						phoneNumber = value as string;
					}
					if (key.startsWith("data.")) {
						const newKey = key.slice(5); // Remove "data." prefix
						acc[`jobs_form_${newKey}`] = value; // Assign value to the new key
					} else {
						acc[`jobs_form_${key}`] = value; // Keep the key-value pair as is
					}
					return acc;
				},
				{}
			);
			gtmValues = {
				...gtmValues,
				jobs_form_email: email,
				jobs_form_phone_number: phoneNumber,
        		phone: phoneNumber
			};

			sendGTMEvent(gtmValues);
			if (source === "fountain") {
				if (response.meta.success) {
					handleSuccess(response);
				} else {
					if (response.data?.message === "Already applied") {
						handleRepeated();
					} else {
						handleError(response);
					}
				}
			} else if (source === "greenhouse") {
				if (response.meta.success) {
					sendGTMEvent({ event: "hqApplicationSuccess" });
					console.log("You have successfully applied for this job");
					setFormStatus("thank_you");
				} else {
					console.log("Form error", response.data?.error);
					setError(response.data?.error);
					sendGTMEvent({ event: "jobApplicationFail" });
				}
			}
			setLoading(false);
		})
		.catch((error) => {
			console.log("Error:", error);
			setLoading(false);
		});

	function handleSuccess(response) {
		console.log("You have successfully applied for this job");
		sendGTMEvent({ event: "jobApplicationSuccess" });
		if (response.data?.portal_url) {
			window.location.href = response.data.portal_url;
		}
		setFormStatus("thank_you");
	}

	function handleRepeated() {
		console.log("You have already applied for this job");
		setFormStatus("already_applied");
		sendGTMEvent({ event: "jobApplicationRepeated" });
	}

	function handleError(response) {
		console.log("Form error", response.data?.message);
		setError(response.data?.message);
		sendGTMEvent({
			event: "formError",
			errorUrl: window.location.href, // Capture the current page URL
		});
		sendGTMEvent({ event: "jobApplicationFail" });
	}
};
