"use client";

import React, { useRef } from "react";
import Image from "next/image";
import { gsap } from "gsap";
import { useGSAP } from "@gsap/react";
import { ScrollTrigger } from "gsap/ScrollTrigger";

interface SliderRowProps {
	reverse?: boolean;
	copy: { title: string; subtitle: string; description?: string };
	image?: string;
	animation?: boolean;
}

const Row = ({ reverse, copy }: SliderRowProps) => {
	return (
		<div
			className={`min-h-[200px] md:min-h-[230px] text-pretty md:text-balance text-left border-grey7 border-y bg-grey1 md:py-12 md:px-12 px-8 py-12 z-20 flex items-center`}
		>
			<div
				className={`max-w-screen-2xl mx-auto w-full ${reverse ? "md:float-right" : ""}`}
			>
				<h2
					className={`md:text-6xl text-5xl font-extrabold [&_*]:font-forma-djr-display ${
						reverse ? "float-right" : ""
					}`}
					dangerouslySetInnerHTML={{ __html: copy.title }}
				/>
			</div>
		</div>
	);
};

const Content = ({ reverse, copy, image }: SliderRowProps) => {
	return (
		<div
			className={`min-h-[1000px] relative md:py-12 md:px-12 px-8 py-12 box-border text-grey5 `}
		>
			<div
				className={`max-w-screen-2xl mx-auto flex flex-col-reverse gap-12 justify-between ${
					reverse ? "md:flex-row-reverse" : "md:flex-row"
				}`}
			>
				<div className="max-w-screen-sm">
					<div
						className={`w-full flex flex-col gap-12 justify-center h-full md:pb-20 ${
							reverse ? "float-right" : "float-left"
						}`}
					>
						<p
							dangerouslySetInnerHTML={{
								__html: copy.description,
							}}
						/>
						{copy.checklist && (
							<ul className="flex flex-col gap-10 list-none m-0 p-0">
								{copy.checklist.map((item, index) => (
									<li key={index} className="">
										<div className="flex items-start gap-2">
											<Image
												src="/assets/general/img/check_circle.png"
												alt="Check"
												width={30}
												height={30}
											/>
											<p
												dangerouslySetInnerHTML={{
													__html: item,
												}}
											/>
										</div>
									</li>
								))}
							</ul>
						)}
					</div>
				</div>
				{image && (
					<Image
						className={`h-[400px] md:h-[800px] w-full md:w-[600px] object-cover object-center rounded-lg`}
						src={image}
						alt="Slider Image"
						width={600}
						height={750}
					/>
				)}
			</div>
		</div>
	);
};

const SliderRow = ({ data, animation }: SliderRowProps) => {
	const { reverse = false, copy, image, id } = data;
	const containerRef = useRef(null);

	if (animation) {
		gsap.registerPlugin(ScrollTrigger);
		useGSAP(() => {
			let tl = gsap.timeline({
				scrollTrigger: {
					trigger: containerRef.current.children[0],
					pin: true,
					pinSpacing: false,
					start: "top 0",
					end: "bottom 0",
					scrub: 2,
					onLeave: () => ScrollTrigger.refresh(),
					onEnter: () => ScrollTrigger.refresh(),
					markers: false,
				},
			});
			tl.to(containerRef.current.children[0], {
				// calculate the total size of the container when it grows. Content + Row * 2
				height:
					containerRef.current.children[0].children[0].children[1]
						.clientHeight +
					containerRef.current.children[0].children[0].children[0]
						.clientHeight *
						2 +
					"px",
				ease: "power2.out",
				duration: 2,
			});
		}, [containerRef]);
	}

	return (
		<div className="overflow-hidden relative" ref={containerRef}>
			<div
				className={` ${animation ? "max-h-[200px] md:max-h-[230px]" : ""}`}
			>
				<Row reverse={reverse} copy={copy} />
				<Content copy={copy} image={image} reverse={reverse} />
			</div>
		</div>
	);
};

export default SliderRow;
